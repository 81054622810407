const windowGlobal = typeof window !== 'undefined' && window

const LocalStorage = {
  setItem: (key, value) => {
    if (windowGlobal) {
      localStorage.setItem(key, value)
    }
  },
  getItem: (key) => {
    if (windowGlobal) {
      return JSON.parse(localStorage.getItem(key))
    }
  },
}

export default LocalStorage
