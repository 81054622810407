export const FRAME_PLUS_FEATURES = {
  album: {
    heading: 'Albums',
    text: 'Curate photo & video playlists.',
    alt: 'Icon showing a photo album',
  },
  remote: {
    heading: 'Remote control',
    text: 'Adjust settings from far away.',
    alt: 'Icon of a remote control',
  },
  cards: {
    heading: 'Digital Greeting Cards',
    text: 'Create and send animated e-cards to celebrate special occasions.',
    alt: 'Icon of an envelope representing digital greeting cards',
  },
  cloud: {
    heading: 'Cloud backup',
    text: 'Never worry about losing your photos and videos.',
    alt: 'Icon of a cloud with an arrow indicating backup',
  },
}

export const FRAME_NO_PLAN = {
  check: {
    heading: 'Base features',
    text: 'You can send and view photos and videos, plus add captions for free forever.',
    alt: 'Icon of a checkmark',
  },
  close: {
    heading: 'No Plus features',
    text: 'You will not be able to access remote settings, or use greeting cards or doodles.',
    alt: 'Icon of an x',
  },
}
