import styled from 'styled-components'
import { breakpoints } from 'src/utils/styles/global-styles'

export const ShippingContainer = styled.div`
  padding: ${(props) => (props.frame ? '32px 0px 10px' : '10px 0px')};
  @media (max-width: ${breakpoints.s}px) {
    padding: 20px 0px 0px;
  }
`
export const ShippingOption = styled.div`
  display: flex;
  align-items: center;
  padding: 8px 0px;
`
export const ShippingImageContainer = styled.div`
  width: 25px;
`
export const ShippingText = styled.p`
  margin: 0px 0px 0px 8px;
  color: ${(props) => (props.special ? '#2F8F3E' : props.theme.grayDark)};
  font-family: ${(props) => (props.special ? 'FilsonProBold' : 'FilsonProBook')} !important;
`
