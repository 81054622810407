import React, { useState } from 'react'
import { GatsbyImage } from 'gatsby-plugin-image'
import { Trans } from 'gatsby-plugin-react-i18next'
import { useStaticQuery, graphql } from 'gatsby'
import PropTypes from 'prop-types'
import { Subheader, CustomizationOption } from 'src/components/FrameProductForm/styles'
import {
  DropDownContainer,
  DropDownLabel,
  DropDownImageContainer,
  DropDownIconContainer,
  DropDownContainerOptions,
} from '../../templates/CalendarProductPage/styles'
import { Bubble, BubbleContainer, DropDownLabelOptions, Strikethough, Price } from './styles'

const Dropdown = ({
  title,
  size,
  handleSizeSelection,
  initialShowDropdown = false,
  frameOptions,
  handleStyleSelection,
  setSelectedColor,
}) => {
  const { dropdownicon, dropupicon } = useStaticQuery(graphql`
    query DropdownQuery {
      dropdownicon: file(relativePath: { eq: "calendar/down-arrow.png" }) {
        childImageSharp {
          gatsbyImageData(quality: 100, placeholder: NONE)
        }
      }
      dropupicon: file(relativePath: { eq: "calendar/up-arrow.png" }) {
        childImageSharp {
          gatsbyImageData(quality: 100, placeholder: NONE)
        }
      }
    }
  `)

  const [showDropdown, setShowDropdown] = useState(initialShowDropdown)

  const toggleDropdown = () => setShowDropdown(!showDropdown)
  const PRODUCT_DROPDOWN_LABELS = {
    10: '10" Frame',
    '10F2': '10" Frame 2',
    15: '15" Frame',
  }
  const getProductDetailsBySize = (sizeKey) => {
    const selectedProduct = frameOptions.find(({ sizeKey: key }) => key === sizeKey)
    return {
      originalPrice: selectedProduct.originalPrice,
      discountedPrice: selectedProduct.discountedPrice,
      bubbleCopy: selectedProduct.bubbleCopy,
      regularPrice: selectedProduct.regularPrice,
    }
  }
  const productDetails = getProductDetailsBySize(size)
  const showDiscountedPrice =
    productDetails.discountedPrice !== productDetails.originalPrice &&
    productDetails.discountedPrice !== 'NONE'
  const priceDisplay =
    productDetails.originalPrice === 'NONE'
      ? productDetails.regularPrice
      : productDetails.originalPrice

  return (
    <>
      <CustomizationOption>
        <Subheader>
          <Trans>{title}</Trans>
        </Subheader>
        <DropDownContainer onClick={toggleDropdown}>
          <DropDownLabel>
            <p>{PRODUCT_DROPDOWN_LABELS[size]}</p>
            <BubbleContainer>
              {productDetails.bubbleCopy !== 'NONE' && (
                <Bubble blue={productDetails.bubbleCopy === 'NEW'}>
                  {productDetails.bubbleCopy}
                </Bubble>
              )}
              {showDiscountedPrice ? (
                <p>
                  <Strikethough>${priceDisplay}</Strikethough>
                  <Price>${productDetails.discountedPrice}</Price>
                </p>
              ) : (
                <p>${priceDisplay}</p>
              )}
            </BubbleContainer>
          </DropDownLabel>
          <DropDownImageContainer>
            <DropDownIconContainer>
              <GatsbyImage
                image={
                  showDropdown
                    ? dropupicon.childImageSharp.gatsbyImageData
                    : dropdownicon.childImageSharp.gatsbyImageData
                }
                alt="Drop Down Arrow"
              />
            </DropDownIconContainer>
          </DropDownImageContainer>
        </DropDownContainer>
      </CustomizationOption>
      {showDropdown && (
        <DropDownContainerOptions margin>
          {Object.entries(PRODUCT_DROPDOWN_LABELS).map(([key, label]) => {
            if (size !== key) {
              const optionDetails = getProductDetailsBySize(key)
              const optionShowDiscountedPrice =
                optionDetails?.discountedPrice !== 'NONE' &&
                optionDetails.discountedPrice !== optionDetails.originalPrice
              return (
                <DropDownLabelOptions
                  key={`${size}-${key}-dropdown`}
                  onClick={() => {
                    handleSizeSelection(key)
                    handleStyleSelection(key === '10F2' ? 'Wood' : 'Classic')
                    setSelectedColor(key === '10F2' ? 'Walnut' : 'Black')
                    setShowDropdown(false)
                  }}
                >
                  {label}
                  <BubbleContainer>
                    {optionDetails?.bubbleCopy !== 'NONE' && (
                      <Bubble blue={optionDetails.bubbleCopy === 'NEW'}>
                        {optionDetails.bubbleCopy}
                      </Bubble>
                    )}
                    {optionShowDiscountedPrice ? (
                      <p>
                        <Strikethough>${optionDetails.originalPrice}</Strikethough>
                        <Price>${optionDetails.discountedPrice}</Price>
                      </p>
                    ) : (
                      <p>${optionDetails.originalPrice}</p>
                    )}
                  </BubbleContainer>
                </DropDownLabelOptions>
              )
            }
            return null
          })}
        </DropDownContainerOptions>
      )}
    </>
  )
}

export default Dropdown
Dropdown.propTypes = {
  title: PropTypes.string.isRequired,
  size: PropTypes.string.isRequired,
  handleSizeSelection: PropTypes.func.isRequired,
  initialShowDropdown: PropTypes.bool.isRequired,
  frameOptions: PropTypes.array.isRequired,
  handleStyleSelection: PropTypes.func.isRequired,
  setSelectedColor: PropTypes.func.isRequired,
}
