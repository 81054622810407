import React, { useRef } from 'react'
import PropTypes from 'prop-types'
import { graphql } from 'gatsby'
import { withTranslation } from 'gatsby-plugin-react-i18next'

import Analytics from 'src/utils/Analytics'
import SEO from 'src/components/seo'
import { GatsbyImage } from 'gatsby-plugin-image'
import { DefaultFrameConfig } from 'src/utils/FrameProductPage'
import ReviewsSection from '../../components/ReviewsSection'
import { Anchor } from '../../components/ReviewsSection/styles'
import { LOCALIZATION, IS_FOREIGN_COUNTRY } from '../../utils/constants'
import FrameProductForm from '../../components/FrameProductForm'
import {
  ShopifyDescription,
  Thumbnail,
  ThumbnailRow,
  ThumbnailButton,
  Section,
  Container,
  ProductGridLeft,
  ProductGridRight,
  Grid,
  ProductImage,
  GiftModeImage,
  LeftArrow,
  RightArrow,
  ArrowContainer,
} from './styles'

class FrameProductPage extends React.Component {
  constructor(props) {
    super(props)
    const { data } = props
    const { product } = data
    const defaultConfig = DefaultFrameConfig(product.handle, {
      color: 'Black',
      size: '10',
      style: 'Classic',
    })
    this.state = {
      shownImageIndex: 0,
      size: defaultConfig.size,
      style: defaultConfig.style,
      isPlusProduct: true,
      color: defaultConfig.color,
      isScrollable: false,
    }
    this.setShownImageIndex = this.setShownImageIndex.bind(this)
    this.analyticsTitle = this.analyticsTitle.bind(this)
    this.handleSizeSelection = this.handleSizeSelection.bind(this)
    this.handleStyleSelection = this.handleStyleSelection.bind(this)
    this.handleColorSelection = this.handleColorSelection.bind(this)
    this.handlePlusProduct = this.handlePlusProduct.bind(this)
    this.scrollableThumbnailContainerRef = React.createRef()
  }

  componentDidMount() {
    this.checkIfScrollable()
    const {
      data: { product },
    } = this.props
    const { sku } = product.variants[0]
    setTimeout(() => {
      Analytics.track(`Viewed ${this.analyticsTitle()} Product Page`, { sku })
      const { size, color, style } = this.state
      Analytics.track(`Viewed ${size} ${size === '10F2' ? style : ''} ${color} Frame Product Page`)
    }, 3000)
  }

  componentDidUpdate() {
    this.checkIfScrollable()
  }

  handleSizeSelection(size) {
    this.setState({ size, color: 'Black', style: 'Classic' })
  }

  handleStyleSelection(style) {
    const styleColorMap = {
      Cover: 'Black',
      Wood: 'Walnut',
      'Shadow Box': 'Charcoal',
    }
    const color = styleColorMap[style] || 'Black'
    this.setState({ style, color })
  }

  handleColorSelection(string) {
    this.setState({ color: string })
  }

  handlePlusProduct(bool) {
    this.setState({ isPlusProduct: bool })
  }

  setShownImageIndex(shownImageIndex) {
    this.setState({ shownImageIndex })
  }

  checkIfScrollable() {
    const scrollableElement = this.scrollableThumbnailContainerRef.current
    if (scrollableElement) {
      const newisScrollable = scrollableElement.scrollWidth > scrollableElement.clientWidth
      const { isScrollable } = this.state
      if (newisScrollable !== isScrollable) {
        this.setState({ isScrollable: newisScrollable })
      }
    }
  }

  scrollLeft() {
    if (this.scrollableThumbnailContainerRef.current) {
      this.scrollableThumbnailContainerRef.current.scrollBy({ left: -130, behavior: 'smooth' })
    }
  }

  scrollRight() {
    if (this.scrollableThumbnailContainerRef.current) {
      this.scrollableThumbnailContainerRef.current.scrollBy({ left: 130, behavior: 'smooth' })
    }
  }

  analyticsTitle() {
    const { data } = this.props
    return 'Frame'
  }

  render() {
    const { shownImageIndex, isPlusProduct, color, size, style, isScrollable } = this.state
    const { data, pageContext, t } = this.props
    const { product, returnGuarantee, badge } = data
    const {
      variants: [{ storefrontId: returnGuaranteeId }],
    } = returnGuarantee || { variants: [{ storefrontId: null }] }
    let { images } = product
    const {
      frame15,
      frameBlack,
      frameWhite,
      frameSilver,
      frameGold,
      framePoppy,
      frameClassicBlack,
      frameClassicWhite,
      frameClassicSage,
      frameClassicLagoon,
      frameCoverBlack,
      frameCoverWhite,
      frameWoodWalnut,
      frameWoodBirch,
      frameShadowBoxCharcoal,
      frameShadowBoxAluminum,
      frameClassicBlackWithPlus,
      frameClassicWhiteWithPlus,
      frameClassicSageWithPlus,
      frameClassicLagoonWithPlus,
      frameCoverBlackWithPlus,
      frameCoverWhiteWithPlus,
      frameWoodWalnutWithPlus,
      frameWoodBirchWithPlus,
      frameShadowBoxCharcoalWithPlus,
      frameShadowBoxAluminumWithPlus,
      snapFrame,
    } = pageContext
    let productDescription = product
    let frameWithPlus = {}
    let frameWithoutPlus = {}
    if (!IS_FOREIGN_COUNTRY) {
      const productMap = {
        '10.Black': frameBlack.data.shopifyProduct,
        '10.Silver': frameSilver.data.shopifyProduct,
        '10.White': frameWhite.data.shopifyProduct,
        '10.Gold': frameGold.data.shopifyProduct,
        '10.Poppy': framePoppy.data.shopifyProduct,
        '15.Black': frame15.data.shopifyProduct,
        '10F2.Black': frameClassicBlack.data.shopifyProduct,
        '10F2.White': frameClassicWhite.data.shopifyProduct,
        '10F2.Sage': frameClassicSage.data.shopifyProduct,
        '10F2.Lagoon': frameClassicLagoon.data.shopifyProduct,
        '10F2.Aluminum': frameShadowBoxAluminum.data.shopifyProduct,
        '10F2.Charcoal': frameShadowBoxCharcoal.data.shopifyProduct,
        '10F2.Walnut': frameWoodWalnut.data.shopifyProduct,
        '10F2.Birch': frameWoodBirch.data.shopifyProduct,
        '10F2.Cover.Black': frameCoverBlack.data.shopifyProduct,
        '10F2.Cover.White': frameCoverWhite.data.shopifyProduct,
        '10F2.Black.Plus': frameClassicBlackWithPlus.data.shopifyProduct,
        '10F2.White.Plus': frameClassicWhiteWithPlus.data.shopifyProduct,
        '10F2.Sage.Plus': frameClassicSageWithPlus.data.shopifyProduct,
        '10F2.Lagoon.Plus': frameClassicLagoonWithPlus.data.shopifyProduct,
        '10F2.Aluminum.Plus': frameShadowBoxAluminumWithPlus.data.shopifyProduct,
        '10F2.Charcoal.Plus': frameShadowBoxCharcoalWithPlus.data.shopifyProduct,
        '10F2.Walnut.Plus': frameWoodWalnutWithPlus.data.shopifyProduct,
        '10F2.Birch.Plus': frameWoodBirchWithPlus.data.shopifyProduct,
        '10F2.Cover.Black.Plus': frameCoverBlackWithPlus.data.shopifyProduct,
        '10F2.Cover.White.Plus': frameCoverWhiteWithPlus.data.shopifyProduct,
      }

      let imageKey = `${size}.${color}`
      if (size === '10F2') {
        if (style === 'Cover') {
          imageKey = `${size}.Cover.${color}`
        }
        frameWithPlus = productMap[`${imageKey}.Plus`] || null
        frameWithoutPlus = productMap[imageKey] || null
        if (isPlusProduct) {
          images = frameWithPlus.images || images
          productDescription = frameWithPlus
        } else {
          images = frameWithoutPlus.images || images
          productDescription = frameWithoutPlus
        }
      } else {
        images = productMap[imageKey].images || images
        productDescription = productMap[`${size}.${color}`]
      }
    }
    if (size === '15') {
      images = frame15.data.shopifyProduct.images
      productDescription = frame15.data.shopifyProduct
    }
    if (IS_FOREIGN_COUNTRY && size !== '15' && frameBlack) {
      images = frameBlack.data.shopifyProduct.images || images
      productDescription = frameBlack.data.shopifyProduct || productDescription
    }
    const shownImage = images[shownImageIndex]

    const is15inFrame = color === 'Black' && size !== '15'
    const badgeData = badge.edges[0].node
    const badgeList = badgeData.showPdpBadge

    const includes15inFrame = badgeList.includes('15" Black Frame')
    const hasColorBadge = badgeList.some((frame) => frame.includes(color))

    let displayBadge =
      (!IS_FOREIGN_COUNTRY && (is15inFrame ? includes15inFrame : hasColorBadge)) ||
      badgeList.includes(LOCALIZATION)

    if (!IS_FOREIGN_COUNTRY) {
      displayBadge = true
    }

    const badgeType = IS_FOREIGN_COUNTRY ? 'internationalBadge' : 'usaFrameBadge'
    const shownBadge =
      shownImageIndex < badgeData[badgeType].length ? badgeData[badgeType][shownImageIndex] : null

    return (
      <>
        <SEO
          title={t('Skylight Frame - Digital Photo Frame')}
          description={product.description}
          imagePath={product.images[0].gatsbyImageData.images.fallback.src}
          path={`/products/${product.handle}/`}
        />
        <Section>
          <Container>
            <Grid>
              <ProductGridLeft>
                <ProductImage
                  image={shownImage.gatsbyImageData}
                  key={shownImage.id}
                  alt={product.title}
                  className={size === '15' ? 'zoom' : 'standard'}
                />
                {displayBadge && shownBadge && (
                  <GiftModeImage>
                    <GatsbyImage
                      image={shownBadge.gatsbyImageData}
                      alt="Frame Product Page Badge"
                    />
                  </GiftModeImage>
                )}
                <ArrowContainer>
                  {isScrollable && (
                    <LeftArrow
                      type="button"
                      onClick={() => this.scrollLeft()}
                      aria-label="Previous images"
                    >
                      ‹
                    </LeftArrow>
                  )}
                  <ThumbnailRow ref={this.scrollableThumbnailContainerRef} start={isScrollable}>
                    {images.map((image, index) => (
                      <ThumbnailButton
                        key={index}
                        type="button"
                        onClick={() => {
                          this.setShownImageIndex(index)
                        }}
                      >
                        <Thumbnail
                          image={image.gatsbyImageData}
                          key={image.id}
                          alt={`${product.title} Thumbnail ${index}`}
                        />
                      </ThumbnailButton>
                    ))}
                  </ThumbnailRow>
                  {isScrollable && (
                    <RightArrow
                      type="button"
                      onClick={() => this.scrollRight()}
                      aria-label="Next images"
                    >
                      ›
                    </RightArrow>
                  )}
                </ArrowContainer>
              </ProductGridLeft>
              <ProductGridRight>
                <FrameProductForm
                  product={product}
                  frame15={frame15}
                  frameBlack={frameBlack}
                  frameWhite={frameWhite}
                  frameSilver={frameSilver}
                  frameGold={frameGold}
                  framePoppy={framePoppy}
                  handle={product.handle}
                  title={product.title}
                  simplifiedProductTitle={this.analyticsTitle()}
                  handleSizeSelection={this.handleSizeSelection}
                  handleStyleSelection={this.handleStyleSelection}
                  updateSelectedColor={this.handleColorSelection}
                  isPlusProduct={this.handlePlusProduct}
                  setShownImageIndex={this.setShownImageIndex}
                  size={size}
                  style={style}
                  color={color}
                  frameWithPlus={frameWithPlus}
                  frameWithoutPlus={frameWithoutPlus}
                  returnGuaranteeId={returnGuaranteeId}
                  snapFrame={snapFrame}
                />
                <ShopifyDescription
                  dangerouslySetInnerHTML={{ __html: productDescription.descriptionHtml }}
                />
              </ProductGridRight>
            </Grid>
            <Anchor id="review" />
          </Container>
          <ReviewsSection productId="7073751498804" name="The Skylight Frame" />
        </Section>
      </>
    )
  }
}

FrameProductPage.propTypes = {
  data: PropTypes.object.isRequired,
  pageContext: PropTypes.object.isRequired,
  t: PropTypes.func,
}

export const query = graphql`
  query ($handle: String!, $language: String!) {
    product: shopifyProduct(handle: { eq: $handle }) {
      id
      title
      handle
      productType
      description
      descriptionHtml
      shopifyId
      variants {
        id
        title
        price
        shopifyId
        sku
      }
      images {
        id
        gatsbyImageData
      }
    }
    returnGuarantee: shopifyProduct(handle: { eq: "120-days-guarantee" }) {
      variants {
        storefrontId
      }
    }
    locales: allLocale(
      filter: { ns: { in: ["common", "frame-product"] }, language: { eq: $language } }
    ) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
    badge: allContentfulFrameCalendarProductPageBadgeV2 {
      edges {
        node {
          usaFrameBadge {
            gatsbyImageData(layout: FULL_WIDTH, placeholder: NONE)
          }
          internationalBadge {
            gatsbyImageData(layout: FULL_WIDTH, placeholder: NONE)
          }
          showPdpBadge
        }
      }
    }
  }
`

export default withTranslation()(FrameProductPage)
