import styled from 'styled-components'

import { breakpoints } from 'src/utils/styles/global-styles'
import { darken, lighten } from 'polished'

export const StickyButtonContainer = styled.div`
  display: none;
  @media (max-width: ${breakpoints.s}px) {
    display: ${(props) => (props.isVisible ? 'block' : 'none')};
    position: fixed;
    left: 0px;
    bottom: 0px;
    padding: 16px 0px;
    width: 100%;
    z-index: 9999;
    background-color: ${(props) => props.theme.cream};
    box-shadow: 0px -4px 4px rgba(0, 0, 0, 0.1);
    text-align: center;
    .sticky-button {
      width: 90%;
      font-family: 'FilsonPro';
      background-color: ${(props) => props.theme.coral};
      color: white;
      text-decoration: none;
      border-radius: 28px;
      border: none;
      font-size: 18px;
      font-weight: normal;
      padding: ${(props) => (props.widerButton ? '15px 50px' : '15px 30px')};
      line-height: 1.28;
      letter-spacing: 0.09px;
      &:hover {
        cursor: pointer;
        background-color: ${(props) => darken(0.1, props.theme.coral)};
      }
      &:focus {
        outline: none;
        box-shadow: ${(props) => `0 0 0 0.2rem ${lighten(0.1, props.theme.coral)}`};
      }
      @media (max-width: ${breakpoints.m}px) {
        cursor: pointer;
        font-size: 16px;
        line-height: 1.19;
        letter-spacing: 0.08px;
      }
    }
  }
`
