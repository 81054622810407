import styled, { css } from 'styled-components'

export const BogoContainer = styled.div`
  border-radius: 10px;
  border: ${(props) => (props.selected ? '2px solid #2078AF' : '1px solid #9099A0 ')};
  padding: 12px 16px;
  position: relative;
  background-color: ${(props) => (props.selected ? '#FAFFFF' : props.theme.cream)};
`
export const DealTitle = styled.p`
  font-family: 'FilsonPro' !important;
  font-size: 20px;
  margin: 0px;
  font-weight: 425;
`
export const DealDetails = styled.div`
  display: flex;
  flex-wrap: wrap;
`
export const Detail = styled.p`
  color: ${(props) =>
    props.gray ? props.theme.grayLight : props.red ? props.theme.coral : props.theme.grayDark};
  margin: 0px 5px 0px 0px;
  font-family: ${(props) => (props.red ? 'FilsonProBold' : 'FilsonProBook')} !important;
  font-size: 14px;
  line-height: 1.4;
  font-size: 14px;
`
export const DetailSpecifics = styled.span`
  color: ${(props) =>
    props.gray ? props.theme.grayLight : props.red ? props.theme.coral : props.theme.grayDark};
`

export const ColorTitle = styled.p`
  font-family: 'FilsonProBold' !important;
  margin: 8px 0px -8px;
`
export const CheckContainer = styled.div`
  cursor: pointer;
  margin: 0px 8px 0px 0px;
`
export const HeadlineContainer = styled.div`
  display: flex;
  align-items: center;
`
export const SaleBubble = styled.div`
  border-radius: 20px;
  background: ${(props) => props.theme.coral};
  position: absolute;
  top: 10px;
  right: 10px;
  padding: 4px 16px 2px;
  @media (max-width: 385px) {
    top: -10px;
    right: 14px;
    padding: 2px 10px 0px;
  }
`
export const Sale = styled.p`
  font-family: 'FilsonPro' !important;
  color: ${(props) => props.theme.cream};
  margin: 0px;
  font-size: 13px;
`
export const Description = styled.p`
  font-family: 'FilsonProBook' !important;
  b {
    font-family: 'FilsonProBold' !important;
    font-style: italic;
  }
  span {
    color: ${(props) => props.theme.coral};
  }
`
export const BogoCustomizationOption = styled.div`
  margin: ${(props) => (props.frame ? '30px 0px' : '0px 0px 16px')};
  text-align: left;
  span {
    font-family: 'FilsonProBold' !important;
  }
`
