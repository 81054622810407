import React from 'react'
import PropTypes from 'prop-types'
import { StickyButtonContainer } from './styles'

const StickyButton = ({ label, onClick, isVisible, oos }) => (
  <StickyButtonContainer isVisible={isVisible}>
    <button type="button" onClick={onClick} className="sticky-button" disabled={oos}>
      {label}
    </button>
  </StickyButtonContainer>
)

export default StickyButton

StickyButton.propTypes = {
  label: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
  isVisible: PropTypes.bool.isRequired,
  oos: PropTypes.bool.isRequired,
}
