import styled, { css } from 'styled-components'
import { breakpoints } from 'src/utils/styles/global-styles'
import { DropDownLabelOption } from '../../templates/CalendarProductPage/styles'

export const Bubble = styled.p`
  font-size: 11px !important;
  background-color: ${(props) => (props.blue ? props.theme.blueDark : props.theme.grayDark)};
  font-family: 'FilsonProBook' !important;
  color: white;
  border-radius: 100px;
  padding: 2px 8px;
  margin-right: 10px !important;
  text-align: center;
  justify-content: center;
  @media (max-width: 360px) {
    font-size: 10px !important;
    padding: 2px 5px;
    margin-right: 5px !important;
  }
`
export const BubbleContainer = styled.div`
  display: flex;
  align-items: center;
`
export const DropDownLabelOptions = styled(DropDownLabelOption)`
  padding-right: 55px;
  @media (max-width: 340px) {
    padding-right: 15px;
  }
`
export const Strikethough = styled.span`
  text-decoration: line-through;
  margin-right: 8px;
  @media (max-width: 360px) {
    margin-right: 5px;
  }
`
export const Price = styled.span`
  color: ${(props) => props.theme.coral};
`
