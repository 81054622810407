import React, { useRef } from 'react'
import PropTypes from 'prop-types'
import { useTranslation, Trans } from 'gatsby-plugin-react-i18next'

import close from 'src/images/icons/close.svg'
import check from 'src/images/icons/check.svg'
import album from 'src/images/icons/album.svg'
import cards from 'src/images/icons/cards.svg'
import remote from 'src/images/icons/remote.svg'
import cloud from 'src/images/icons/cloud.svg'

import { MostPopular } from 'src/templates/CalendarProductPage/styles'
import AddToCartButton from '../AddToCartButton'

import { Subheader, ButtonContainer } from '../FrameProductForm/styles'
import {
  Grid,
  SaveInfo,
  PriceAndExplainerContainer,
  ProductSelectionPricePoint,
  FistMonthFree,
  ProductSelectionBox,
  IconContainer,
  CalFeature,
  FeatureTextContainer,
  CalFeatureHeading,
  CalFeatureText,
  ClickableProductSelectionBox,
  ProductSelectionBoxWrapper,
  Icon,
} from './styles'
import { FRAME_PLUS_FEATURES, FRAME_NO_PLAN } from './constants'

const FramePickYourPrice = ({
  selectedProduct,
  setSelectedProduct,
  frameOriginalPrice,
  frameWithPlus,
  frameWithoutPlus,
  quantityVal,
  onAddToCart,
  overridenPrice,
}) => {
  const { t } = useTranslation('common')
  const calPlusFeatureIcons = { album, cards, remote, cloud }
  const calNoPlusFeatureIcons = { check, close }
  let frameWithoutPlusPrice = frameWithoutPlus.variants[0].price
  const frameWithPlusPrice = frameWithPlus.variants[0].price
  const noplusSelectionRef = useRef(null)

  const handleSelectionScroll = (productType) => {
    setSelectedProduct(productType)
    setTimeout(() => {
      if (productType === 'standard' && noplusSelectionRef.current) {
        const elementPosition =
          noplusSelectionRef.current.getBoundingClientRect().top + window.scrollY
        const bannerElement = document.querySelector('.banner')
        const scrollOffset = bannerElement ? bannerElement.offsetHeight + 80 : 80

        window.scrollTo({
          top: elementPosition - scrollOffset,
          behavior: 'smooth',
        })
      }
    }, 0)
  }

  let displayedFrameOriginalPrice = frameOriginalPrice
  let displayedFrameWithPlusPrice = frameWithPlusPrice
  if (overridenPrice && overridenPrice.frameOriginalPrice) {
    displayedFrameOriginalPrice = overridenPrice.frameOriginalPrice
  }
  if (overridenPrice && overridenPrice.discountedPriceWithPlus) {
    displayedFrameWithPlusPrice = overridenPrice.discountedPriceWithPlus
  }
  const displayedStikethroughDesign = overridenPrice && overridenPrice.discountedPrice

  if (displayedStikethroughDesign) {
    frameWithoutPlusPrice = overridenPrice.discountedPrice
  }
  const displayedPriceSavings = (frameWithoutPlusPrice - displayedFrameWithPlusPrice).toFixed(2)

  return (
    <>
      <Subheader>
        <Trans>Pick your price</Trans>
      </Subheader>
      {selectedProduct === 'standard' ? (
        <>
          <ProductSelectionBoxWrapper>
            <MostPopular>
              <Trans>Most Popular</Trans>
            </MostPopular>
            <ClickableProductSelectionBox
              popular
              style={{ marginTop: -16 }}
              onClick={() => {
                setSelectedProduct('with-plus')
              }}
            >
              <SaveInfo>
                <Trans
                  i18nKey="Save more with Plus"
                  values={{
                    priceSaving: displayedPriceSavings,
                  }}
                >
                  Save ${displayedPriceSavings} more with Plus Plan
                </Trans>
              </SaveInfo>
              <ProductSelectionPricePoint red>
                <strike>${displayedFrameOriginalPrice}</strike>{' '}
                <span>${displayedFrameWithPlusPrice}</span>
              </ProductSelectionPricePoint>
            </ClickableProductSelectionBox>
          </ProductSelectionBoxWrapper>
          <ProductSelectionBox ref={noplusSelectionRef}>
            <SaveInfo>
              <Trans>Price without Plus</Trans>
            </SaveInfo>
            {displayedFrameOriginalPrice === frameWithoutPlusPrice ? (
              <ProductSelectionPricePoint>${frameWithoutPlusPrice}</ProductSelectionPricePoint>
            ) : (
              <ProductSelectionPricePoint red>
                <strike>${displayedFrameOriginalPrice}</strike>
                <span>${frameWithoutPlusPrice}</span>
              </ProductSelectionPricePoint>
            )}
            <Grid>
              {Object.keys(FRAME_NO_PLAN).map((key) => {
                const feature = FRAME_NO_PLAN[key]
                const icon = calNoPlusFeatureIcons[key]
                return (
                  <CalFeature key={feature.heading}>
                    <IconContainer>
                      <Icon
                        type="image/svg+xml"
                        data={icon}
                        alt={feature.heading}
                        aria-label={feature.alt}
                      >
                        svg-animation
                      </Icon>
                    </IconContainer>
                    <FeatureTextContainer>
                      <CalFeatureHeading>{feature.heading}</CalFeatureHeading>
                      <CalFeatureText>{feature.text}</CalFeatureText>
                    </FeatureTextContainer>
                  </CalFeature>
                )
              })}
            </Grid>
            <ButtonContainer fullWidth className="regular-cta">
              <AddToCartButton
                label={t('Add to Cart')}
                handleClick={() => onAddToCart(frameWithoutPlus.variants[0].shopifyId, quantityVal)}
              />
            </ButtonContainer>
          </ProductSelectionBox>
        </>
      ) : (
        <>
          <ProductSelectionBoxWrapper>
            <MostPopular>
              <Trans>Most Popular</Trans>
            </MostPopular>
            <ProductSelectionBox style={{ marginTop: -16 }} popular>
              <SaveInfo>
                <Trans
                  i18nKey="Save more with Plus"
                  values={{
                    priceSaving: displayedPriceSavings,
                  }}
                >
                  Save ${displayedPriceSavings} more with Plus Plan
                </Trans>
              </SaveInfo>
              <PriceAndExplainerContainer>
                <ProductSelectionPricePoint>
                  <strike>${displayedFrameOriginalPrice}</strike>{' '}
                  <span>${displayedFrameWithPlusPrice}</span>
                </ProductSelectionPricePoint>
              </PriceAndExplainerContainer>
              <FistMonthFree>
                The first month of Skylight Plus is free, then it's $39/year. This includes:
              </FistMonthFree>
              <Grid two>
                {Object.keys(FRAME_PLUS_FEATURES).map((key) => {
                  const feature = FRAME_PLUS_FEATURES[key]
                  const icon = calPlusFeatureIcons[key]
                  return (
                    <CalFeature key={feature.heading}>
                      <IconContainer>
                        <Icon
                          type="image/svg+xml"
                          data={icon}
                          alt={feature.heading}
                          aria-label={feature.alt}
                        >
                          svg-animation
                        </Icon>
                      </IconContainer>
                      <FeatureTextContainer>
                        <CalFeatureHeading>{feature.heading}</CalFeatureHeading>
                        <CalFeatureText>{feature.text}</CalFeatureText>
                      </FeatureTextContainer>
                    </CalFeature>
                  )
                })}
              </Grid>
              <ButtonContainer fullWidth className="regular-cta">
                <AddToCartButton
                  label={t('Add to Cart')}
                  handleClick={() => onAddToCart(frameWithPlus.variants[0].shopifyId, quantityVal)}
                />
              </ButtonContainer>
            </ProductSelectionBox>
          </ProductSelectionBoxWrapper>
          <ClickableProductSelectionBox
            onClick={() => {
              handleSelectionScroll('standard')
            }}
          >
            <SaveInfo>
              <Trans>Price without Plus</Trans>
            </SaveInfo>
            <PriceAndExplainerContainer>
              {displayedFrameOriginalPrice === frameWithoutPlusPrice &&
              !displayedStikethroughDesign ? (
                <ProductSelectionPricePoint>${frameWithoutPlusPrice}</ProductSelectionPricePoint>
              ) : (
                <ProductSelectionPricePoint>
                  <strike>${displayedFrameOriginalPrice}</strike>
                  <span>${frameWithoutPlusPrice}</span>
                </ProductSelectionPricePoint>
              )}
            </PriceAndExplainerContainer>
          </ClickableProductSelectionBox>
        </>
      )}
    </>
  )
}

FramePickYourPrice.propTypes = {
  selectedProduct: PropTypes.string.isRequired,
  setSelectedProduct: PropTypes.func.isRequired,
  frameOriginalPrice: PropTypes.string.isRequired,
  frameWithPlus: PropTypes.object.isRequired,
  frameWithoutPlus: PropTypes.object.isRequired,
  quantityVal: PropTypes.number,
  onAddToCart: PropTypes.func.isRequired,
  overridenPrice: PropTypes.object.isRequired,
}

export default FramePickYourPrice
