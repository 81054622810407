import styled from 'styled-components'

export const DiscountCalloutContainer = styled.div`
  margin: 20px 0 20px 0;
`

export const DiscountCalloutText = styled.p`
  margin: 0;
  font-weight: 800;
  font-style: italic;
  color: #2e8540;
`
