import styled, { css } from 'styled-components'
import { breakpoints } from 'src/utils/styles/global-styles'

export const Grid = styled.div`
  display: grid;
  grid-template-columns: ${(props) => (props.two ? '1fr 1fr' : '1fr')};
  grid-template-rows: ${(props) => (props.two ? '1fr 1fr' : '1fr')};
  gap: 16px;
  margin: 16px 0px;
  @media (max-width: ${breakpoints.s}px) {
    grid-template-columns: 1fr;
    grid-template-rows: 1fr;
  }
`
export const SaveInfo = styled.h3`
  font-family: 'P22MackinacProBook';
  font-size: 24px;
  line-height: 32px;
  margin-bottom: 0px;
  margin: 0px 0px 3px;
  @media (max-width: 360px) {
    font-size: 22px;
  }
`
export const ProductPriceSelection = styled.p`
  color: #444;
  font-family: 'FilsonProBook';
  font-size: 16px;
  line-height: 24px;
  text-decoration-line: strikethrough;
`
export const PriceAndExplainerContainer = styled.section`
  display: flex;
  flex-direction: column;
  text-align: right;
  margin: 0px;
`
export const ProductSelectionPricePointCss = css`
  font-size: 18px;
  font-family: 'FilsonPro';
  line-height: 24px;
  letter-spacing: 0.1px;
  margin: 0;
  strike {
    color: ${(props) => props.theme.grayDark};
    padding-right: 8px;
    font-family: 'FilsonPro';
  }
`

export const ProductSelectionPricePoint = styled.section`
  ${ProductSelectionPricePointCss};
  span {
    color: ${(props) => props.theme.coral} !important;
  }
  display: flex;
`

export const ClickableProductSelectionPricePoint = styled.section`
  ${ProductSelectionPricePointCss}
  color: ${(props) => props.theme.golden};
  display: flex;
  align-items: center;
`
export const FistMonthFree = styled.p`
  font-family: 'FilsonPro';
  font-size: 16px;
  line-height: 24px;
`
const productSelectionBoxCss = css`
  border-radius: 8px;
  margin-bottom: 10px;
`

export const ProductSelectionBox = styled.section`
  ${productSelectionBoxCss}
  padding: ${(props) => (props.popular ? '32px 16px 16px 16px' : '16px')};
  border: 3px solid ${(props) => props.theme.blueDark};
  background-color: white;
  li {
    font-family: 'FilsonProBook';
    font-weight: normal !important;
    b {
      font-family: 'FilsonProBold';
    }
  }
  button {
    width: 100%;
    margin-bottom: 10px;
  }
  @media (max-width: ${breakpoints.s}px) {
    padding-bottom: 10px;
  }
`
export const IconContainer = styled.div`
  width: 24px;
  height: 24px;
  min-width: 24px;
  min-height: 24px;
  @media (max-width: ${breakpoints.s}px) {
  }
`
export const CalFeature = styled.div`
  display: flex;
  flex: 1;
`
export const FeatureTextContainer = styled.div`
  margin-left: 16px;
`
export const CalFeatureHeading = styled.h3`
  font-family: 'FilsonPro';
  font-size: 16px;
  font-style: normal;
  line-height: 24px;
  margin: 0px;
`
export const CalFeatureText = styled.p`
  font-family: 'FilsonPro';
  font-size: 16px;
  font-style: normal;
  line-height: 24px;
  margin: 0px;
`

export const ClickableProductSelectionBox = styled.section`
  ${productSelectionBoxCss}
  padding: ${(props) => (props.popular ? '32px 16px 16px 16px' : '16px')};
  border: 1px solid #ececec;
  background-color: white;
  cursor: pointer;
  :hover {
    border: 1px solid ${(props) => props.theme.blueDark};
  }
`
export const FrameSelection = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  border: ${(props) => (props.blue ? '3px solid #2178af' : '1px solid #9199A0')};
  padding: ${(props) => (props.blue ? '13px' : '15px')};
  border-radius: 8px;
  cursor: pointer;
  margin: 0px 10px 0px 0px;
  -webkit-tap-highlight-color: transparent;
  position: relative;
  min-width: 165px;
  @media (max-width: ${breakpoints.s}px) {
    width: 47%;
    min-width: auto;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;
  }
  @media (max-width: 375px) {
    width: 46%;
    padding: 10px;
  }
`
export const ProductSelectionBoxWrapper = styled.div`
  position: relative;
  margin-top: 20px;
`
export const Icon = styled.object`
  pointer-events: none;
`
